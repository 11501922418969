<template>
  <List el="xsm" style="max-width: 1200px">
    <List el="column-between xsm">
      <Text el="h2">Users</Text>
      <!-- <Btn
        @click="to('/dashboard/quiz/upload')"
        v-if="$store.getters.isAdmin"
        >Upload</Btn
      > -->
    </List>
    <UserManagement />
  </List>
</template>
<script>
import router from "@/router";
import UserManagement from "../components/UserManagement.vue";
let filter = require("lodash/filter");
let lowerCase = require("lodash/lowerCase");
let includes = require("lodash/includes");

export default {
  components: {
    // Layout,
    UserManagement
  },
  data() {
    return {
      videos: {
        searchInput: "",
        section: "JAVA",
        filters: {
          tag: "JAVA",
          status: "publish",
        },
      },
    };
  },
  watch: {
    "videos.section": function (val) {
      this.videos.filters.tag = val;
      this.$store.commit("collapseSection", true);
    },
  },
  methods: {
    formatDate(dateString) {
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const d = new Date(`${dateString} 00:00:00`);
      return `${dateString}, ${days[d.getDay()]}`;
    },
    to(path) {
      this.$store.commit("collapseSection", false);
      router.push(path);
    },
  },
  computed: {
    filteredQuiz() {
      // var input = this.modules.searchInput;
      let x = this;
      return filter(this.$store.state.quiz.list, function (item) {
        if (x.videos.section === "draft") {
          return (
            item.status == "draft" &&
            lowerCase(`${item.date} ${item.label}`).search(
              lowerCase(x.videos.searchInput)
            ) > -1
          );
        } else {
          return (
            includes(item.tags, x.videos.filters.tag) == true &&
            item.status == "publish" &&
            lowerCase(`${item.date} ${item.label}`).search(
              lowerCase(x.videos.searchInput)
            ) > -1
          );
        }
      });
    },
  },
  mounted() {
    let pathArr = this.$route.path.split("/");
    let endPath = pathArr.pop();
    if (endPath == "upload") {
      this.$store.commit("collapseSection", false);
    } else {
      this.$store.commit("collapseSection", true);
    }
    this.$store.commit("quiz/loadList");
  },
};
</script>
<style lang="scss" scoped>
</style>
